






















































































































import EmojiFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/EmojiFilterHandlerModule/EmojiFilterHandlerModuleBuilder";
import {
  EmojiTypeEnum
} from "@/includes/logic/Modules/modules/modules/EmojiFilterHandlerModule/EmojiFilterHandlerModule";

import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../../components/filters/layout/FilterSettingLayout.vue";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput,
    NumberInput,
    SwitchInput
  },
})
export default class EmojiFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: EmojiFilterHandlerModuleBuilder

  emojiTypesOptions: Array<SelectOption> = [
    {
      label: this.$t('emoji_filer_emoji_type_option_all').toString(),
      value: EmojiTypeEnum.All
    },
    {
      label: this.$t('emoji_filer_emoji_type_option_custom').toString(),
      value: EmojiTypeEnum.Custom
    },
    {
      label: this.$t('emoji_filer_emoji_type_option_standart').toString(),
      value: EmojiTypeEnum.Standard
    },
  ]
}
